.header-narrow {
    width: 100%;
    position: fixed;
    top: 0;
    background: #07193d;
    z-index: 10;
}

.header-narrow .dropdown-icon-container {
    display: flex;
    justify-content: flex-end; /* Align dropdown icon to the right */
    padding: 10px 20px;
}

.header-narrow .dropdown-icon {
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.header-narrow .bar {
    height: 3px;
    background-color: white; /* White for visibility */
    transition: all 0.3s linear; /* Consistent speed for bar animations */
    border-radius: 3px;
    
}



.header-narrow .dropdown-icon.open .top-bar {
    transform: translateY(8px) rotate(45deg);
}

.header-narrow .dropdown-icon.open .middle-bar {
    display: none;
}

.header-narrow .dropdown-icon.open .bottom-bar {
    transform: translateY(-8px) rotate(-45deg);
}

.header-narrow .dropdown-menu {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 1.3rem;
    background-color: #07193d;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s linear, padding 0.2s linear; /* Ensure consistent speed for height and padding */
}

.header-narrow .dropdown-menu.show {
    max-height: 300px; /* Adjust as needed */
    padding: 3px 0 20px; /* Add padding for spacing */
}

.header-narrow .menu-link {
    text-decoration: none;
    align-items: center;
    display: flex;
    gap: 12px; /* Space between icon and text */
    justify-content: center; /* Center icon and text */
    -webkit-tap-highlight-color: transparent;
    color: #e8eaed; /* Default text color */
    transition: color 0.3s ease; /* Smooth color transition for text */
}

.header-narrow .menu-icon {
    user-select: none; /* Prevent text selection on icons */
    pointer-events: none; /* Prevent icons from being interactive */
    width: 20px;
    height: 20px;
    margin-bottom: 1.5px;
    fill: #e8eaed; /* Default icon color */
    transition: fill 0.3s ease; /* Smooth color transition for icons */
}

/* Hover effect */
.header-narrow .menu-link:hover {
    color: #00aced; /* Blue text color on hover */
}

.header-narrow .menu-link:hover .menu-icon {
    fill: #00aced; /* Blue icon color on hover */
}





@media (max-width: 450px) {

    .header-narrow .menu-link {
        font-size: 1.1rem;
    }
  }
  
  @media (max-width: 400px) {
  
    .header-narrow .menu-link {
        font-size: 1.12rem;
    }
  }
  
  @media (max-width: 350px) {
  
    .header-narrow .menu-link {
        font-size: 1.15rem;
    }
  }
