/* Section containing the intro and grid */
.projects-grid {
    flex-direction: column;
    margin-top: 250px;
    min-height: 80vh; /* Ensures the section always fills the viewport */
}


/* Introductory text styling */
.projects-grid .text-container {
    text-align: center;
}

.projects-grid .heading {
    margin-bottom: 50px;
}

.projects-grid .paragraph {
    margin-bottom: 50px;
}

/* Grid styling */
.project {
    margin: 0 10vw 0;
    display: flex;
    flex-wrap: wrap; /* Ensures wrapping of items */
    justify-content: center; /* Centers items horizontally */
    gap: 20px; /* Consistent spacing between items */
    margin-bottom: 50px;
}


@media (max-width: 450px) {

    .projects-grid {
        margin: 220px 0 50px;
    }

    .project{
        margin: 0 9vw 0;
    }
  }

  @media (max-width: 400px) {
    .project{
        margin: 0 8vw 0;
    }
  }

  @media (max-width: 350px) {
    .project{
        margin: 0 7vw 0;
    }
  }