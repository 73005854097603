* {
    box-sizing: border-box;
  }
  
  .header-wide {
    width: 100%;
    position: fixed;
    top: 0;
    background: transparent;
    padding: 10px;
    z-index: 10;
    transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
  }
  
  .header-wide.scrolled {
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
  }
  
  .header-wide .container {
    display: flex;
    justify-content: space-between;
    max-width: 70%;
    margin: 0 auto;
  }
  
  
  .header-wide .nav-links {
    display: flex;
    gap: 20px;
    margin-left: auto;
  }
  
  .header-wide .nav-link {
    position: relative;
    text-decoration: none;
    padding: 5px 10px;
    display: flex;
    align-items: center; /* Align icon with text */
  }
  

  .header-wide .icon {
    margin-bottom: 3px;
    margin-right: 8px; /* Space between the icon and text */
    width: 21px; /* Adjust size as needed */
    height: 21px; /* Adjust size as needed */
  }
  

  
  
  .header-wide .nav-link::after {
    content: '';
    position: absolute;
    left: 9%; /* Adjust to center the shorter underline */
    bottom: 1px;
    width: 0;
    height: 3px;
    background-color: #00aced;
    transition: width 0.3s ease-in-out, left 0.3s ease-in-out; /* Add transition for smooth animation */
    border-radius: 10px;
  }
  
  .header-wide .nav-link:hover::after {
    width: 85%; /* Set shorter width for the underline */
    left: 9%; /* Keep it centered */
  }
