/* Individual project card */
.project-card {
    width: 300px;
    background-color: transparent;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.project-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);; /* Intensify the glow on hover */
}

.project-card .image {
    opacity: 0.9;
    width: 100%; /* Ensures the image spans the width of the container */
    aspect-ratio: 3 / 2; /* Adjust this to match your desired container ratio */
    border-radius: 4px 4px 2px 2px; /* Keeps the rounded corners */
}


/* Project title */
.project-card .heading {
    margin: 25px 0 5px;
}

/* Project description */
.project-card .paragraph {
    line-height: 1.4;
}

/* Wrapper for GitHub button to align it at the bottom */
.project-card .button-wrapper {
    -webkit-tap-highlight-color: transparent;
    margin-top: auto; /* Pushes the button to the bottom */
}

.project-card .button {
    padding: 10px 20px;
    font-size: 1em;
    background-color: #0075b4;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.project-card .button:hover {
    background-color: #0090dd;
}

