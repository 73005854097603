.identity {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 200px 0 0;
  min-height: 30vh;
}

.identity .text-container {
  margin-right: 10vw; /* Proportional right margin */
  margin-bottom: 2vw;
}

.identity .heading {
  margin-bottom: 20px;
}

.identity .wave {
  display: inline-block;
  animation: wave-animation 1.5s ease-in-out infinite;
  transform-origin: 70% 70%;
}

@keyframes wave-animation {
  0% { transform: rotate(0deg); }
  10% { transform: rotate(14deg); }
  20% { transform: rotate(-8deg); }
  30% { transform: rotate(14deg); }
  40% { transform: rotate(-4deg); }
  50% { transform: rotate(10deg); }
  60% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
}

.identity .typed-style {
  font-size: 1.3rem;
  font-family: 'Montserrat';
}


.identity .typed-cursor {
  font-size: 1.3rem; /* Adjust size */
  animation: blink-animation 1s infinite; /* Blinking effect */
}

@keyframes blink-animation {
  0%, 50% {
    opacity: 1;
  }
  51%, 100% {
    opacity: 0;
  }
}

.identity .image {
  opacity: 0.9;
  max-width: 300px;
}


@media (max-width: 900px) {
  .identity {
    flex-direction: column; /* Stack content vertically */
  }


  .identity .text-container {
    margin-right: 0vw;
    margin-bottom: 0vw;
  }
}

@media (max-width: 450px) {

  .identity .wave{
    font-size: 1.3rem;
  }

  .identity .typed-style, .identity .typed-cursor {
    font-size: 1.1rem;
  }

  .identity .image {
    max-width: 270px;
  }
}

@media (max-width: 400px) {

  .identity .wave{
    font-size: 1.25rem;
  }

  .identity .typed-style, .identity .typed-cursor {
    font-size: 1.05rem;
  }

  .identity .image {
    max-width: 250px;
  }
}

@media (max-width: 350px) {

  .identity .wave{
    font-size: 1.2rem;
  }

  .identity .typed-style, .identity .typed-cursor {
    font-size: 1rem;
  }

  .identity .image {
    max-width: 220px;
  }
}