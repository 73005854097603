.home-intro {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 100px 0;
  }
  
  .home-intro .text-container {
    margin-right: 10vw; /* Proportional right margin */
    max-width: 40%;
  }
  
  .home-intro .image {
    opacity: 0.9;
    max-width: 300px;
  }
  
  @media (max-width: 900px) {
    .home-intro {
      flex-direction: column; /* Stack content vertically */
      margin: 100px 10vw; /* Fixed top and bottom, proportional left and right */
    }
  
    .home-intro .text-container {
      margin-right: 0; /* Remove right margin */
      max-width: 100%; /* Allow full width for text */
    }
  
    .home-intro .image {
      /* Adjust image size */
      margin-top: 60px; /* Add spacing between text and image */
    }
  }

  @media (max-width: 450px) {
  
    .home-intro .image {
      max-width: 260px;
      margin-top: 50px;
    }
  }

  @media (max-width: 400px) {
  
    .home-intro .image {
      max-width: 230px;
      margin-top: 40px;
    }
  }

  @media (max-width: 350px) {
  
    .home-intro .image {
      max-width: 200px;
      margin-top: 30px;
    }
  }