.socials {
  background-color: transparent;
  text-align: center;
  padding: 20px 0;
  width: 100%;
  margin-top: auto; /* Push it to the end of the page's content */
  margin-bottom: 10px;
}

.socials .icon-container {
  margin-top: 30px;
}

.socials .link {
  -webkit-tap-highlight-color: transparent;
  margin: 0 20px;
}

.socials .icon {
  transition: fill 0.3s; /* Add smooth transition for hover */
}

.socials .link:hover .icon {
  fill: #00aced; /* Change the SVG color */
}

@media (max-width: 450px) {

  .socials .icon {
    width: 20px;
  }
}

@media (max-width: 400px) {

  .socials .icon {
    width: 19px;
  }
}

@media (max-width: 350px) {

  .socials .icon {
    width: 18px;
  }
}