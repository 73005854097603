.about-intro {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 270px;
  min-height: 40vh;
}

.about-intro .text-container {
  margin-bottom: 3vw;
  margin-right: 10vw; /* Proportional right margin */
  max-width: 40%; /* Limit text container width */
}

.about-intro .heading {
  text-align: center;
  margin-bottom: 30px; /* Keep the spacing below the headline */
}


.about-intro .paragraph {
  text-align: left;
}

.about-intro .image {
  opacity: 0.9;
  max-width: 300px; /* Fixed max width */
}

/* Responsive styles */
@media (max-width: 900px) {
  .about-intro {
    flex-direction: column; /* Stack content vertically */
    margin: 200px 10vw 0;
  }

  .about-intro .text-container {
    margin-bottom: 30px;
    margin-right: 0; /* Remove right margin */
    max-width: 100%; /* Allow full width for text */
  }

  
}

@media (max-width: 450px) {
  .about-intro .text-container {
    margin-bottom: 25px;
  }
  
  .about-intro .image {
    max-width: 260px;
  }
}

@media (max-width: 400px) {
  .about-intro .text-container {
    margin-bottom: 20px;
  }

  .about-intro .image {
    max-width: 230px;
  }
}

@media (max-width: 350px) {
  .about-intro .text-container {
    margin-bottom: 15px;
  }

  .about-intro .image {
    max-width: 200px;
  }
}