.skills-grid {
    display: flex;
    flex-direction: column; /* Ensures title and grid stack vertically */
    justify-content: center;
    align-items: center;
    padding: 2rem;
    margin-top: 120px;
  }
  
  .skills-grid .heading {
    margin-bottom: 50px;
  }
  
  .skills-grid .skill {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    max-width: 1000px;
    width: 95%;
    margin: 0 auto 50px;
}