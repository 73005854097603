

/* Background styling */
.background {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #041331;
  z-index: -1;
}

/* Star styles */
.star {
  position: absolute;
  background-color: white;
  border-radius: 50%;
  animation: twinkle 5s infinite ease-in-out;
}

/* Twinkling animation */
@keyframes twinkle {
  0%, 100% {
    opacity: 0.8;
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(.85);
  }
}

/* Apply scrollbar styles globally */
* {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #0f3fa0 #041331; /* Firefox */
  color: #fff;
}


h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat';
}

*:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
  font-family: 'Roboto';
}

.keyword {
  color: #00aced;
}

@media (max-width: 450px) {
  h1, h2, h3, h4, h5, h6 {
    font-size: 1.4rem;
  }

  *:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
    font-size: 0.95rem;
  }
}

@media (max-width: 400px) {
  h1, h2, h3, h4, h5, h6 {
    font-size: 1.3rem;
  }

  *:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
    font-size: 0.925rem;
  }
}

@media (max-width: 350px) {
  h1, h2, h3, h4, h5, h6 {
    font-size: 1.2rem;
  }

  *:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
    font-size: 0.9rem;
  }
}