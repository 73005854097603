.skill-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 120px; /* Fixed width for uniform size */
  height: 120px; /* Adjusted height */
}

.skill-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
}

.skill-card img {
  width: 48px; /* Fixed width for uniform size */
  height: 48px; /* Fixed height for uniform size */
  object-fit: contain; /* Ensures the entire image fits within the dimensions without distortion */
  margin-top: 1rem;
  margin-bottom: 1rem;
  opacity: 0.9; /* Set transparency level here */
  transition: opacity 0.3s ease; /* Smooth transition effect */
}


.skill-card h3 {
  font-size: 0.9rem;
  text-align: center;
  margin-top: auto;
}